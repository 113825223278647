import React from "react";
import HomePage from "../../Components/Header/Header"
import ContactBanner from "../../Components/Contact/ContactBanner/ContactBanner"
import ContactForm from "../../Components/Contact/Contact"
import FooterPage from "../../Components/Footer/Footer"


const Contact = () => {
    return (
        <React.Fragment>
            <HomePage />
            <ContactBanner />
            <ContactForm />
            <FooterPage/>
        </React.Fragment >
    )
}
export default Contact;